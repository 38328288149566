<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title print-hide">{{ title }}</h1>

    <div class="container-print">
      <b-field
        label="Correo a usar para enviar orden de compra (Requerido)"
        expanded
        label-position="inside"
        class="special-margin"
        v-if="sendProviderEmail"
      >
        <b-select
          placeholder="Ejemplo: Compras"
          icon="at"
          expanded
          v-model="selectedEmail"
        >
          <option value="" disabled>selecciona un correo</option>
          <option
            v-for="singleEmail in companyEmails"
            :value="singleEmail._id"
            :key="singleEmail._id"
          >
            {{ singleEmail.CORREO }}
          </option>
        </b-select>
      </b-field>
      <b-field
        label="Correos a donde enviar orden de compra (Opcional)"
        expanded
        label-position="inside"
        class="special-margin required-input"
        v-if="sendProviderEmail"
      >
        <b-taginput
          v-model="providerSendEmails"
          ellipsis
          icon="label"
          placeholder="Correos para envio de orden de compra"
          :before-adding="(tagToAdd) => validateEmail(tagToAdd)"
        >
        </b-taginput>
      </b-field>
    </div>

    <div class="container-print" ref="requestOrder">
      <img
        v-if="companyInformation.LOGO_URL"
        :src="companyInformation.LOGO_URL"
        alt="Company logo"
        style="margin-top: 1rem; width: 20%; margin-bottom: 1rem"
      />
      <p class="special-margin">
        <strong>
          {{
            companyInformation.LEYENDA_SUPERIOR
              ? companyInformation.LEYENDA_SUPERIOR
              : ""
          }}</strong
        >
      </p>
      <table width="100%">
        <tr class="logotype-container">
          <td>
            <div class="logotype-type" :style="[backgroundStyle, textStyle]">
              {{ title }} #{{ this.purchaseOrderInformation.CLAVE_PED }}
            </div>
          </td>
        </tr>
      </table>
      <br />
      <div style="display: flex">
        <div style="margin-bottom: 20px; width: 50%">
          <strong>Sucursal de Envío</strong><br />
        </div>

        <div style="margin-bottom: 20px; width: 50%; text-align: end">
          <strong>{{
            formatCreationDate(purchaseOrderInformation.creationDate)
          }}</strong
          ><br />
        </div>
      </div>
      <table width="100%">
        <tr>
          <td>
            <table width="100%">
              <tr>
                <td>
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Unidad:</strong>
                  {{ purchaseOrderInformation.ALMACEN.NOMBRE_ALM }}<br />
                  <strong>Dirección:</strong>
                  {{ purchaseOrderInformation.ALMACEN.DOMICIL_ALM }}
                  {{ purchaseOrderInformation.ALMACEN.NUM_EXTER }}
                  {{ purchaseOrderInformation.ALMACEN.NUM_INTER }}
                  {{ purchaseOrderInformation.ALMACEN.NOMBRE_MUNICIPIO }},
                  {{ purchaseOrderInformation.ALMACEN.ESTADO_ALM }},
                  {{ purchaseOrderInformation.ALMACEN.CP_ALM }}<br />
                </td>
                <!--
                <td v-if="purchaseOrderInformation.VENTA">
                  <strong>Nombre:</strong>
                  {{ companyName }} <br />
                  <strong>Unidad:</strong>
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.NOMBRE_ALM
                  }}<br />
                  <strong>Dirección:</strong>
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.DOMICIL_ALM }}
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.NUM_EXTER }}
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.NUM_INTER }}
                  {{
                    purchaseOrderInformation.VENTA.CLAVE_ALMACEN
                      .NOMBRE_MUNICIPIO
                  }},
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.ESTADO_ALM }},
                  {{ purchaseOrderInformation.VENTA.CLAVE_ALMACEN.CP_ALM }}
                  <br />
                </td>
                -->
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br />
      <div
        style="
          background: rgba(0, 0, 0, 0.1);
          padding-left: 20px;
          padding-top: 20px;
        "
        v-if="buyerInformation"
      >
        <strong>Información del comprador</strong><br />
        <table width="100%">
          <tr>
            <td
              style="
                width: 50%;
                padding-bottom: 20px;
                padding-top: 20px;
                vertical-align: top;
              "
            >
              <strong>Nombre:</strong>
              {{ buyerInformation.NOMBRE }} {{ buyerInformation.PATERNO }}
              {{ buyerInformation.MATERNO }}<br />
            </td>
            <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
              <strong>Email:</strong>
              {{ buyerInformation.EMAIL ? buyerInformation.EMAIL : "NA" }}<br />
              <strong>Télefono:</strong>
              {{ buyerInformation.TEL_MOVIL ? buyerInformation.TEL_MOVIL : "NA"
              }}<br />
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div
        style="padding-left: 20px; padding-top: 10px; padding-bottom: 10px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Información del proveedor</strong><br />
      </div>
      <div>
        <table width="100%">
          <tr>
            <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
              <strong>Nombre:</strong>
              {{ purchaseOrderInformation.PRV.NOMBRE_PRV }}<br />
              <strong>Dirección:</strong>
              {{ purchaseOrderInformation.PRV.DOMICIL_PRV }}
              {{ purchaseOrderInformation.PRV.ESTADO_PRV }}
              {{ purchaseOrderInformation.PRV.NOMBRE_MUNICIPIO_PRV }}
              {{ purchaseOrderInformation.PRV.CP_PRV }}<br />
            </td>
            <td style="width: 50%; padding-bottom: 20px; padding-top: 20px">
              <strong>Email:</strong>
              {{
                purchaseOrderInformation.PRV.EMAIL
                  ? purchaseOrderInformation.PRV.EMAIL
                  : "NA"
              }}<br />
              <strong>Télefono:</strong>
              {{
                this.purchaseOrderInformation.PRV.CEL_PRV
                  ? this.purchaseOrderInformation.PRV.CEL_PRV
                  : "NA"
              }}<br />
            </td>
          </tr>
        </table>
      </div>

      <br />
      <div class="table-overflow">
        <table
          width="100%"
          style="border-collapse: collapse; border-bottom: 1px solid #eee"
          v-if="!printWithoutPrices"
        >
          <tr>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              #
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Clave
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Artículo
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Cantidad
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Unidad
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              v-if="purchaseOrderInformation.ieps > 0"
              :style="[backgroundStyle, textStyle]"
            >
              IEPS
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Costo
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Costo Final
            </td>
          </tr>
          <div
            class="display-content"
            v-for="(singleItem, index) of purchaseOrderItems"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element" v-if="singleItem.article">
              <td class="row" style="text-align: center">{{ index + 1 }}</td>
              <td class="row" style="text-align: center">
                {{ singleItem.article.CLAVE_ART }}
              </td>
              <td class="row" style="text-align: center">
                {{
                  singleItem.PRESENTACION === "UB"
                    ? singleItem.article.DESCRIPCION
                    : singleItem.presentationName
                }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.quantity }}
              </td>
              <td
                class="row"
                style="text-align: center"
                v-if="singleItem.article.UNIDAD_EMP"
              >
                {{ singleItem.article.UNIDAD_EMP.DESCRIP_MED }}
              </td>
              <td
                class="row"
                style="text-align: center"
                v-if="!singleItem.article.UNIDAD_EMP"
              >
                Unidad de medida base
              </td>
              <td
                class="row"
                style="text-align: center"
                v-if="purchaseOrderInformation.ieps > 0"
              >
                {{ singleItem.ieps }}%
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.price | money("MXN", 2) }}
              </td>
              <td class="row" style="text-align: end">
                {{
                  ((singleItem.price - singleItem.discountTotal) *
                    singleItem.quantity)
                    | money("MXN", 2)
                }}
              </td>
            </tr>
          </div>
        </table>
        <table
          width="100%"
          style="
            border-collapse: collapse;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          "
          v-if="printWithoutPrices"
        >
          <tr>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              #
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Clave
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Artículo
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Cantidad
            </td>
            <td
              class="column-header"
              style="font-size: 0.9rem; text-align: center"
              :style="[backgroundStyle, textStyle]"
            >
              Presentación
            </td>
          </tr>
          <div
            class="display-content"
            v-for="(singleItem, index) of purchaseOrderInformation.currentItems"
            :key="singleItem.RENGLON"
          >
            <tr class="article-element" v-if="singleItem.article">
              <td class="row" style="text-align: center">
                {{ index + 1 }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.article.CLAVE_ART }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.article.DESCRIPCION }}
              </td>
              <td class="row" style="text-align: center">
                {{ singleItem.quantity }}
              </td>
              <td
                class="row"
                style="text-align: center"
                v-if="singleItem.article.UNIDAD_EMP"
              >
                {{ singleItem.article.UNIDAD_EMP.DESCRIP_MED }}
              </td>
              <td
                class="row"
                style="text-align: center"
                v-if="!singleItem.article.UNIDAD_EMP"
              >
                Unidad de medida base
              </td>
            </tr>
          </div>
        </table>
        <br />
      </div>
      <div style="margin-bottom: 20px; font-size: 1rem">
        <strong>Peso:</strong> {{ getTotalWeight() }} <strong>Kg</strong>
      </div>
      <table
        width="100%"
        style="padding: 20px; border-radius: 5px"
        v-if="!printWithoutPrices"
        class="not-cut-printing"
      >
        <tr>
          <td
            style="padding-top: 1rem; padding-bottom: 1rem; padding-right: 2%"
          >
            <table width="300px" style="float: right">
              <tr>
                <td><strong>Indirectos:</strong></td>
                <td style="text-align: right">
                  {{ this.purchaseOrderInformation.costs | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Descuentos:</strong></td>
                <td style="text-align: right">
                  {{
                    this.purchaseOrderInformation.discounts | money("MXN", 2)
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>Sub-total:</strong></td>
                <td style="text-align: right">
                  {{ this.purchaseOrderInformation.subtotal | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Costo de Flete:</strong></td>
                <td style="text-align: right">
                  {{
                    this.purchaseOrderInformation.shippingQuantity
                      | money("MXN", 2)
                  }}
                </td>
              </tr>
              <tr>
                <td><strong>IVA:</strong></td>
                <td style="text-align: right">
                  {{ this.purchaseOrderInformation.iva | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>IEPS:</strong></td>
                <td style="text-align: right">
                  {{ this.purchaseOrderInformation.ieps | money("MXN", 2) }}
                </td>
              </tr>
              <tr>
                <td><strong>Total:</strong></td>
                <td style="text-align: right">
                  {{ this.purchaseOrderInformation.total | money("MXN", 2) }}
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <div
        class="alert not-cut-printing"
        style="border-radius: 5px"
        :style="backgroundStyle"
      >
        <strong :style="textStyle">Observaciones</strong> <br />
        <br />
        <b-input
          v-if="!printing"
          type="textarea"
          v-model="purchaseOrderInformation.OBSERVA_PED"
        ></b-input>
        <div
          class="observations-element observations-element-printing not-cut-printing"
          v-if="printing"
          style="background-color: white; padding: 0.625em; min-height: 120px"
        >
          <p>{{ this.purchaseOrderInformation.OBSERVA_PED }}</p>
        </div>
      </div>
      <p class="special-margin-top not-cut-printing">
        <strong>
          {{
            companyInformation.LEYENDA_INFERIOR
              ? companyInformation.LEYENDA_INFERIOR
              : ""
          }}</strong
        >
      </p>
      <div v-if="printing" style="margin-top: 1.5rem" class="not-cut-printing">
        Este pdf fue impreso por coconutcontrol.com
      </div>
    </div>

    <div class="container-print">
      <div class="add-global-controls">
        <b-button type="is-success" @click="print(0)"
          >Descargar sin precios</b-button
        >
        <b-button type="is-success" @click="print(1)"
          >Descargar con precios</b-button
        >

        <b-tooltip
          v-if="
            (!selectedEmail || providerSendEmails.length === 0) &&
            permissions.purchase &&
            permissions.purchase.printOrSendPurchaseOrder === 'S' &&
            sendProviderEmail
          "
          label="Primero debes seleccionar un correo de donde enviar la orden de compra y uno o más correos de destino"
          multilined
        >
          <b-button type="is-success" disabled>Enviar sin precios</b-button>
        </b-tooltip>

        <b-button
          type="is-success"
          @click="sendPurchaseOrder(0)"
          v-if="
            selectedEmail &&
            providerSendEmails.length > 0 &&
            permissions.purchase &&
            permissions.purchase.printOrSendPurchaseOrder === 'S' &&
            sendProviderEmail
          "
          >Enviar sin precios</b-button
        >

        <b-tooltip
          v-if="
            (!selectedEmail || providerSendEmails.length === 0) &&
            permissions.purchase &&
            permissions.purchase.printOrSendPurchaseOrder === 'S' &&
            sendProviderEmail
          "
          label="Primero debes seleccionar un correo de donde enviar la orden de compra y uno o más correos de destino"
          multilined
        >
          <b-button type="is-success" disabled>Enviar con precios</b-button>
        </b-tooltip>

        <b-button
          type="is-success"
          @click="sendPurchaseOrder(1)"
          v-if="
            selectedEmail &&
            providerSendEmails.length > 0 &&
            permissions.purchase &&
            permissions.purchase.printOrSendPurchaseOrder === 'S' &&
            sendProviderEmail
          "
          >Enviar con precios</b-button
        >

        <b-button
          type="is-success"
          @click="
            startConfirmEvent(
              'success',
              confirmPurchaseOrderFunction,
              'Confirmar orden de compra'
            )
          "
          v-if="
            canConfirm &&
            permissions.purchase &&
            permissions.purchase.confirmRequestOrder === 'S'
          "
          >Confirmar compra</b-button
        >

        <b-button type="is-danger" @click="closeModal()" class="cancel-button"
          >Regresar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import moment from "moment";
import { EventBus } from "../../../../event-bus";
import { openConfirmationModalSucces } from "../../../../utils/component-fns";
import { hexToRGB } from "../../../../utils/fns";
// @ is an alias to /src
export default {
  name: "PrintPurchaseOrder",
  props: ["purchaseOrderInformation", "title", "canConfirm"],
  components: {},
  data() {
    return {
      printing: false,
      printWithoutPrices: false,
      providerSendEmails: [],
      selectedEmail: "",
      sendProviderEmail: true,
      buyerInformation: null,
    };
  },
  mounted() {
    moment.locale("es");
    if (this.purchaseOrderInformation.PRV.EMAIL) {
      this.providerSendEmails.push(this.purchaseOrderInformation.PRV.EMAIL);
    }
    if (this.$store.getters.COMPANIES_EMAILS.length > 0) {
      this.selectedEmail = this.$store.getters.COMPANIES_EMAILS[0]._id;
    }
    if (this.purchaseOrderInformation.COMPRADOR) {
      for (const singleEmployee of this.employees) {
        if (singleEmployee._id === this.purchaseOrderInformation.COMPRADOR) {
          this.buyerInformation = singleEmployee;
        }
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async confirmPurchaseOrderFunction() {
      try {
        let pdfContent = "";
        let fileName = "";
        if (this.sendProviderEmail) {
          pdfContent = await this.createPdfHtml(1, true);
          fileName = `${this.title} ${this.purchaseOrderInformation.CLAVE_PED} ${this.purchaseOrderInformation.PRV.NOMBRE_PRV} ${this.companyName}.pdf`;
        }
        let response = await this.$store.dispatch("CONFIRMREQUESTORDER", {
          _id: this.purchaseOrderInformation._id,
          OBSERVA_PED: this.purchaseOrderInformation.OBSERVA_PED,
          sendProviderEmail: this.sendProviderEmail,
          selectedEmail: this.selectedEmail,
          providerSendEmails: this.providerSendEmails,
          pdfFile: pdfContent,
          fileName: fileName,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se confirmó exitosamente la nueva orden de compra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al confirmar la nueva orden de compra",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al confirmar la nueva orden de compra en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     * @desc print the purchase order with the desire format
     */
    async print(type) {
      this.createPdfHtml(type, "print");
      await this.$store.dispatch("PRINTORSENDPURCHASEORDER", {
        _id: this.purchaseOrderInformation._id,
      });
      setTimeout(() => {
        this.printWithoutPrices = false;
      }, 500);
    },
    /**
     * @desc send the purchase order to the provider emails
     */
    async sendPurchaseOrder(type) {
      if (
        this.permissions.purchase &&
        this.permissions.purchase.printOrSendPurchaseOrder === "S"
      ) {
        let pdfContent = await this.createPdfHtml(type, "email");
        let response = await this.$store.dispatch("SENDPDFFILE", {
          providerEmails: this.providerSendEmails,
          selectedEmail: this.selectedEmail,
          fileName: `${this.title} ${this.purchaseOrderInformation.CLAVE_PED} ${this.purchaseOrderInformation.PRV.NOMBRE_PRV} ${this.companyName}.pdf`,
          pdfFile: pdfContent,
        });
        if (response === "Success") {
          await this.$store.dispatch("PRINTORSENDPURCHASEORDER", {
            _id: this.purchaseOrderInformation._id,
          });
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se envió correctamente la orden de compra",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {},
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al enviar la orden de compra",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      }
    },
    /**
     *
     * @desc cretes or generates a pdf file
     */
    async createPdfHtml(type, confirmPurchaseOrder = false) {
      if (type === 0) {
        this.printWithoutPrices = true;
      } else {
        this.printWithoutPrices = false;
      }
      this.printing = true;
      let pdfOptions = {
        filename: `${this.title} ${this.purchaseOrderInformation.CLAVE_PED} ${this.purchaseOrderInformation.PRV.NOMBRE_PRV} ${this.companyName}.pdf`,
        pagebreak: {
          avoid: [
            ".article-element",
            ".observations-element",
            ".not-cut-printing",
          ],
        },
        scale: 1,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0, 0.2, 0, 0.2],
      };
      let result;
      if (confirmPurchaseOrder && this.isMobileNavigator()) {
        result = await html2pdf()
          .set(pdfOptions)
          .from(this.$refs.requestOrder)
          .output("datauristring");
      } else {
        result = await html2pdf()
          .set(pdfOptions)
          .from(this.$refs.requestOrder)
          .save()
          .output("datauristring");
      }
      this.printing = false;
      return result;
    },
    formatCreationDate(date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     */
    startConfirmEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    getTotalWeight() {
      let totalWeight = 0;
      for (const singleArticle of this.purchaseOrderInformation.currentItems) {
        if (singleArticle.article && singleArticle.article.CANT_PESO) {
          totalWeight +=
            singleArticle.quantity * singleArticle.article.CANT_PESO;
        }
      }
      return totalWeight;
    },
  },
  computed: {
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyName() {
      return this.$store.getters.COMPANY_INFORMATION.name;
    },
    companyEmails() {
      return this.$store.getters.COMPANIES_EMAILS;
    },
    permissions() {
      return this.$store.getters.USER.permissions.permissions;
    },
    employees() {
      return this.$store.getters.EMPLOYEES.map((singleEmployee) => {
        return singleEmployee;
      }).filter((singleEmployee) => {
        return singleEmployee.TIPO === "CO" || singleEmployee.TIPO === "VC";
      });
    },
    purchaseOrderItems() {
      return this.purchaseOrderInformation.currentItems.map((singleItem) => {
        const singleItemWithProps = { ...singleItem };
        if (singleItem.article) {
          if (
            singleItem.PRESENTACION === "UB" &&
            singleItem.article.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName =
              singleItem.article.UNIDAD_EMP.DESCRIP_MED;
          } else if (
            singleItem.PRESENTACION === "UB" &&
            !singleItem.article.UNIDAD_EMP
          ) {
            singleItemWithProps.presentationName = "Unidad de medida base";
          } else {
            // Set presentation name
            for (const singlePresentation of singleItem.article
              .PRESENTACIONES) {
              if (singlePresentation._id === singleItem.PRESENTACION) {
                singleItemWithProps.presentationName =
                  singlePresentation.NOMBRE;
              }
            }
          }
        }
        return singleItemWithProps;
      });
    },
    backgroundStyle() {
      return {
        "background-color": this.companyInformation.FONDO
          ? hexToRGB(this.companyInformation.FONDO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    backgroundSecondaryStyle() {
      return {
        "background-color": this.companyInformation.FONDO_SECUNDARIO
          ? hexToRGB(this.companyInformation.FONDO_SECUNDARIO, 1)
          : "rgba(0, 0, 0, 0.045)",
      };
    },
    textStyle() {
      return {
        color: this.companyInformation.TEXTO
          ? hexToRGB(this.companyInformation.TEXTO, 1)
          : "#363636",
      };
    },
    textSecondaryStyle() {
      return {
        color: this.companyInformation.TEXTO_SECUNDARIO
          ? hexToRGB(this.companyInformation.TEXTO_SECUNDARIO, 1)
          : "#363636",
      };
    },
  },
  watch: {
    purchaseOrderInformation() {
      if (this.purchaseOrderInformation.PRV.EMAIL) {
        this.providerSendEmails.push(this.purchaseOrderInformation.PRV.EMAIL);
      }
    },
    companyEmails() {
      this.selectedEmail = this.companyEmails[0]._id;
    },
  },
};
</script>

<style scoped>
@import "./style/PrintPurchaseOrder.css";
@import "../../style/Global.css";
</style>
