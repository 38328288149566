<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar descuento</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" @click="addCost()"
        >Agregar descuento</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los descuentos sin
      nombre no serán <span class="global-form-hint-color"> guardados. </span>
    </div>

    <div
      v-for="(singleDiscount, index) in discounts"
      :key="index"
      class="margin-bottom-add"
    >
      <h3 class="form-division">Descuento {{ singleDiscount.NOMBRE }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Nombre del descuento (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descuento 1"
            expanded
            icon="file-outline"
            v-model="singleDiscount.NOMBRE"
            required
          ></b-input>
        </b-field>
        <b-field
          label="Descripción del descuento (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descripción 1"
            expanded
            icon="newspaper-variant-outline"
            v-model="singleDiscount.DESCRIPCION"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Tipo(Requerido)" expanded label-position="inside">
          <b-select
            placeholder="Tipo"
            icon="slash-forward"
            v-model="singleDiscount.TIPO"
            expanded
            class="required-input"
          >
            <option value="" disabled>Seleccione un tipo</option>
            <option value="PO">Porcentaje</option>
            <option value="CA">Cantidad</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field
        grouped
        class="special-margin"
        v-if="singleDiscount.TIPO === 'PO'"
      >
        <b-field
          label="Porcentaje del descuento (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 500"
            expanded
            icon="percent-outline"
            v-model="singleDiscount.PORCENTAJE"
            required
            type="number"
            step="any"
          />
        </b-field>
      </b-field>

      <b-field
        grouped
        class="special-margin"
        v-if="singleDiscount.TIPO === 'CA'"
      >
        <b-field
          label="Cantidad del descuento (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 500"
            expanded
            icon="currency-usd"
            v-model="singleDiscount.CANTIDAD"
            required
            type="number"
            step="any"
          ></b-input>
        </b-field>
      </b-field>

      <b-button type="is-danger" @click="removeDiscount(index)"
        >Eliminar descuento {{ singleDiscount.NOMBRE }}</b-button
      >
    </div>

    <div class="add-global-controls providers-list-global">
      <b-button type="is-success" @click="sendGlobalDiscount()"
        >Guardar cambios</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../../utils/fns";
import { EventBus } from "../../../../event-bus";
// @ is an alias to /src
export default {
  name: "AddGlobalDiscount",
  props: ["allDiscounts"],
  components: {},
  data() {
    return {
      discounts: this.allDiscounts,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async sendGlobalDiscount() {
      try {
        if (!this.checkNotValid()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los descuentos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        EventBus.$emit("saveGlobalDiscounts", { discounts: this.discounts });
        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se agregaron exitosamente los descuentos",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los descuentos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkNotValid() {
      for (const singleDiscount of this.discounts) {
        if (!singleDiscount.NOMBRE) return false;
        if (singleDiscount.TIPO === "PO" && !singleDiscount.PORCENTAJE)
          return false;
        if (singleDiscount.TIPO === "CA" && !singleDiscount.CANTIDAD)
          return false;
      }
      return true;
    },
    removeDiscount(index) {
      this.discounts.splice(index, 1);
    },
    addCost() {
      this.discounts.push({
        NOMBRE: "",
        DESCRIPCION: "",
        TIPO: "PO",
        PORCENTAJE: 0,
        CANTIDAD: 1,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
