<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Agregar artículos con misma palabra a pedido
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <div class="global-lists-filters-columns">
      <b-checkbox v-model="newSameWordSearch.searchName">
        Buscar en clave
      </b-checkbox>

      <b-checkbox v-model="newSameWordSearch.searchDescription">
        Buscar en descripción
      </b-checkbox>
    </div>

    <b-field grouped class="special-margin">
      <b-field
        label="Palabra buscada (Opcional)"
        expanded
        label-position="inside"
      >
        <b-input
          placeholder="Ejemplo: Martillo"
          expanded
          icon="bank"
          v-model="newSameWordSearch.word"
        ></b-input>
      </b-field>
    </b-field>

    <h3 class="form-division">Artículos seleccionados</h3>

    <div class="table-header table-header-border">
      Cantidad de Artículos Seleccionados: <b>{{ checkedArticles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredArticles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="filteresArticlesSameWord"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="purchase-order-table"
      scrollable
      checkbox-position="left"
      :checked-rows="checkedArticles"
      checkable
      @check-all="handleCheckAll"
      @check="handleCheck"
    >
      <template #empty>
        <div class="has-text-centered">
          No hay productos con la palabra buscada
        </div>
      </template>

      <template slot-scope="props">
        <b-table-column
          label="Clave del artículo"
          field="CLAVE_ART"
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.CLAVE_ART }}</span>
        </b-table-column>

        <b-table-column
          label="Descripción del artículo"
          field="article.DESCRIPCION"
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.DESCRIPCION }}</span>
        </b-table-column>

        <b-table-column
          label="Costo máximo de compra"
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ props.row.COSTO_MIN_VTA | money("MXN", 5) }}
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-success" @click="searchProducts()"
        >Buscar productos con palabra buscada
      </b-button>
      <b-button
        type="is-success"
        @click="addProducts()"
        :disabled="checkedArticles.length === 0"
        >Agregar artículos
      </b-button>
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
// @ is an alias to /src
export default {
  name: "AddSameWordArticles",
  props: ["providerInformation"],
  components: {},
  data() {
    return {
      checkedArticles: [],
      currentPage: 1,
      perPage: 10,
      newSameWordSearch: {
        searchName: true,
        searchDescription: false,
        word: "",
      },
      checkedAll: false,
      filteredArticles: [],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async addProducts() {
      try {
        EventBus.$emit("addSameWordProducts", {
          articles: this.checkedArticles,
        });
        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se agregarón exitosamente los productos buscados",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los productos buscados",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc searches in provider articles for articles with the word searched in CLAVE_ART or DESCRIPCION depending on form input
     */
    searchProducts() {
      this.filteredArticles = [];
      for (const singleArticle of this.articles) {
        if (
          this.newSameWordSearch.searchName &&
          !this.newSameWordSearch.searchDescription
        ) {
          if (
            singleArticle.CLAVE_ART.toLowerCase().includes(
              this.newSameWordSearch.word.toLowerCase()
            )
          ) {
            this.filteredArticles.push(singleArticle);
          }
        } else if (
          this.newSameWordSearch.searchDescription &&
          !this.newSameWordSearch.searchName
        ) {
          if (
            singleArticle.DESCRIPCION.toLowerCase().includes(
              this.newSameWordSearch.word.toLowerCase()
            )
          ) {
            this.filteredArticles.push(singleArticle);
          }
        } else if (
          this.newSameWordSearch.searchDescription &&
          this.newSameWordSearch.searchName
        ) {
          if (
            singleArticle.CLAVE_ART.toLowerCase().includes(
              this.newSameWordSearch.word.toLowerCase()
            ) ||
            singleArticle.DESCRIPCION.toLowerCase().includes(
              this.newSameWordSearch.word.toLowerCase()
            )
          ) {
            this.filteredArticles.push(singleArticle);
          }
        }
      }
      if (this.filteredArticles.length === 0) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "No se encontro ningún producto con los parametros de búsqueda seleccionados",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    handleCheckAll() {
      this.checkedAll = !this.checkedAll;
      const table = this.$refs.filteresArticlesSameWord;
      this.checkedArticles = this.checkedAll
        ? table.$data.newData.slice(0)
        : [];
    },
    handleCheck(rows, row) {
      if (row) {
        const index = this.checkedArticles
          .map((singleArticle) => singleArticle._id)
          .indexOf(row._id);
        if (index >= 0) {
          this.checkedArticles.splice(index, 1);
        } else {
          this.checkedArticles.push(row);
        }
      }
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.totalQuantity = 0;

        // Get available items
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }

        return articleWithProps;
      }).filter((singleArticle) => {
        for (const singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV.toString() ===
              this.providerInformation._id.toString() &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            return true;
          }
        }
        return false;
      });
    },
  },
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
