<template>
  <div class="container add-global-modal-main-container import-modal">
    <h1 class="add-global-modal-main-title">Recibir material usando XML</h1>
    <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
    <span class="global-form-hint-color"> Recuerda: </span> Recibir el material
    usando un archivo XML puede tardar un par de minutos, en especial con
    cuentas con mulitples artículos.
    <b-field>
      <b-upload
        v-model="file"
        :multiple="false"
        drag-drop
        expanded
        required
        accept=".xml"
        class="import-input"
      >
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon icon="upload" size="is-large"></b-icon>
            </p>
            <p>Arraste su archivo o haga click y seleccione su archivo</p>
          </div>
        </section>
      </b-upload>
    </b-field>
    <div class="tags absolut-center-row">
      <span class="tag is-primary" v-if="file">
        {{ file.name }}
        <button
          class="delete is-small"
          type="button"
          @click="deleteInformation()"
        ></button>
      </span>
    </div>
    <div class="add-import-controls">
      <b-button
        type="is-success"
        @click="uploadFile()"
        :disabled="!file"
        class="secondary-button"
        >Recibir material</b-button
      >
    </div>
  </div>
</template>

<script>
// Cleave variable initialization to bind formats to inputs
import Cleave from "cleave.js";
import moment from "moment/moment";
import ReceiveOrderArticles from "@/modules/Purchases/components/ReceiveOrderArticles.vue";

const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "ReceiveXMLMaterial",
  components: {},
  directives: { cleave },
  props: [
    "newPurchaseOrderProp",
    "creationDateProp",
    "startingDateFormatedProp",
    "currentIndexProp",
    "reveiveFullOrder",
    "purchaseOrderInformation",
  ],
  data() {
    return {
      file: null,
      xmlContent: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc uploads new information to the database
     */
    async uploadFile() {
      try {
        const articlesMatched = [];

        if (this.file) {
          let reader = new FileReader();
          reader.onload = async (event) => {
            this.xmlContent = event.target.result;
            const bufferFile = Buffer.from(
              this.xmlContent.replace("data:text/xml;base64,", ""),
              "base64"
            );
            const decodedFile = bufferFile.toString();

            const jsonFromXMl = await this.$store.dispatch(
              "TRANSFORMXML2JSON",
              decodedFile
            );

            const XMLArticles =
              jsonFromXMl["cfdi:Comprobante"]["cfdi:Conceptos"];

            for (const [key, value] of Object.entries(XMLArticles)) {
              let found = false;
              for (const singleArticle of this.articles) {
                for (const singleProviderPrice of singleArticle.PRECIOS_PROV) {
                  if (
                    value.NoIdentificacion ===
                      singleProviderPrice.CLAVE_ARTPRV ||
                    value.Descripcion === singleProviderPrice.CLAVE_ARTPRV
                  ) {
                    articlesMatched.push({
                      article: singleArticle._id,
                      quantity: Number(value.Cantidad),
                    });
                    found = true;
                    break;
                  }
                }

                if (found) {
                  break;
                }
              }
            }

            this.closeModal();

            this.$buefy.modal.open({
              canCancel: ["x"],
              parent: this,
              component: ReceiveOrderArticles,
              props: {
                newPurchaseOrderProp: this.newPurchaseOrderProp,
                creationDateProp: this.creationDateProp,
                startingDateFormatedProp: this.startingDateFormatedProp,
                currentIndexProp: this.currentIndexProp,
                reveiveFullOrder: this.reveiveFullOrder,
                xmlReceivedInformation: articlesMatched,
              },
              hasModalCard: false,
              customClass: "primary-modal-class",
              trapFocus: true,
              fullScreen: false,
              destroyOnHide: true,
            });
          };
          reader.readAsDataURL(this.file);
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al importar la información",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        this.$store.commit("SET_LOADING", false);
      }
    },
    deleteInformation() {
      this.file = null;
    },
  },
  mounted() {},
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };

        return articleWithProps;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Global/style/ImportModal.css";
</style>
