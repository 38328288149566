<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      {{ message }} {{ this.warehouse.NOMBRE_ALM }}
    </h1>
    <WarehousesTransfersList
      :warehouse="warehouse"
      :type="type"
    ></WarehousesTransfersList>
  </div>
</template>

<script>
import WarehousesTransfersList from "../components/WarehousesTransfersList";
// @ is an alias to /src
export default {
  props: ["message", "warehouse", "type"],
  name: "WarehousesTransferComponentsContainer",
  components: {
    WarehousesTransfersList,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
