<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Proveedor
      <span class="global-form-hint-color">{{ provider.NOMBRE_PRV }}</span>
      artículo
      <span class="global-form-hint-color">{{ item.article.DESCRIPCION }}</span>
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Nuevo precio del provedor para el articulo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': providerPriceRequiredErrors.COSTO }"
        :message="{
          'El Nuevo precio del provedor para el articulo no es valido':
            providerPriceRequiredErrors.COSTO,
        }"
      >
        <b-input
          placeholder="Ejemplo: 500"
          expanded
          icon="bank"
          v-model="newProviderPrice.COSTO"
          required
          type="number"
          step="any"
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="changePrice()"
        >Cambiar precio</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../../utils/fns";
import { EventBus } from "../../../../event-bus";
// @ is an alias to /src
export default {
  name: "ChangeProviderPrice",
  props: ["item", "provider"],
  components: {},
  data() {
    return {
      newProviderPrice: {
        COSTO: this.item.price,
      },
      providerPriceRequiredErrors: {
        COSTO: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async changePrice() {
      try {
        this.providerPriceRequiredErrors = validateFields(
          this.newProviderPrice,
          this.providerPriceRequiredErrors
        );
        if (checkValidationErrors(this.providerPriceRequiredErrors)) {
          let response = await this.$store.dispatch("CHANGEPROVIDERPRICE", {
            CLAVE_ART: this.item.article._id,
            CLAVE_PRV: this.provider._id,
            COSTO: this.newProviderPrice.COSTO,
          });
          EventBus.$emit("sendPriceChange", {
            article: this.item.article._id,
            provider: this.provider._id,
            price: this.newProviderPrice,
          });
          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se cambió exitosamente el nuevo precio",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al cambiar el precio en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cambiar el precio en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al cambiar el precio en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
