<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          v-if="
            providers.length === 0 ||
            articles.length === 0 ||
            warehouses.length === 0
          "
          label="Primero debes agregar artículos, proveedores y almacenes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled>Agregar pedido</b-button>
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="openAddPurchaseOrder()"
          v-if="
            providers.length > 0 && articles.length > 0 && warehouses.length > 0
          "
          >Agregar pedido</b-button
        >
      </div>
      <div class="control">
        <b-button type="is-primary" @click="confirmDeleteRequestOrders()"
          >Borrar pedidos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>
    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>

      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Pedidos: <b>{{ filteredPurchaseOrder.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="filteredPurchaseOrder"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="ProductsTable"
      paginated
      :per-page="perPage"
      :checked-rows.sync="checkedOrders"
      :current-page="currentPage"
      :selected.sync="selected"
      :opened-detailed="defaultOpenedDetails"
      checkable
      checkbox-position="left"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.CLAVE_PED}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="desc"
      default-sort="CLAVE_PED"
    >
      <template #empty>
        <div class="has-text-centered">No hay pedidos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          v-bind="column"
          :key="column.field"
          :visible="column.display"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <span v-if="!checkIfValid(props.row[column.field])">{{
            "Sin información"
          }}</span>
          <span v-if="column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field] | money("MXN", 2)
          }}</span>
          <span v-if="!column.money && checkIfValid(props.row[column.field])">{{
            props.row[column.field]
          }}</span>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div class="controls">
                <b-button type="is-primary" @click="showRequestOrder(props.row)"
                  >Ver pedido</b-button
                >
                <b-button
                  type="is-primary"
                  @click="startGenerateReport(props.row)"
                  >Confirmar, descargar o enviar orden de compra</b-button
                >
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import ChangePurchaseOrder from "./ChangePurchaseOrder";
import { basicRuleOfThree, round } from "../../../utils/fns";
import AddRequestOrders from "./AddRequestOrders";
import PrintPurchaseOrder from "../../Global/components/print-formats/PrintPurchaseOrder";
export default {
  name: "ReqestOrdersList",
  props: ["showReception", "seePrices"],
  components: {},
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedOrders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_PED",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NUM_INTERNO_PROV",
          label: "Número de pedido del proveedor",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "FECHA_PRE",
          label: "Fecha prevista de entrega",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_CREACION",
          label: "Tipo de creación",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_PRV",
          label: "Proveedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_GUA",
          label: "Usuario creador",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL_ACT",
          label: "Usuario de ultima edición",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha del pedido",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "SUBTOTAL_PED",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "IVA_PED",
          label: "Iva total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL_PED",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante de recepción",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "IMPRESO",
          label: "Veces descargada o enviada",
          sortable: true,
          searchable: true,
          display: false,
        },
      ],
      filterProvider: "",
      filterWarehouses: [],
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {},
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];
      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: [],
        })
      );
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(
        this.$store.dispatch("GETREQUESTORDERS", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    confirmDeletePurchaseOrders() {
      if (this.checkedOrders.length > 0) {
        this.reloadInformation();
        this.$buefy.dialog.confirm({
          title: "Borrar proveedores",
          message:
            "¿Estas seguro que deseas <b>borrar</b> las ordenes seleccionadas? Esta acción no puede ser cambiada.",
          confirmText: "Borrar proveedores",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEPURCHASEORDERS", this.checkedOrders)
              .then((response) => {
                if (response === "Terminado") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente las ordenes de compra seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar las ordenes de compra en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay ordenes de compra seleccionadas",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddPurchaseOrder() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddRequestOrders,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    formatPurchaseOrder(requestOrder) {
      let currentItems = [];
      for (let singleArticle of requestOrder.PEDIDO_DET) {
        currentItems.push({
          _id: singleArticle._id,
          article: singleArticle.CLAVE_ART,
          quantity: singleArticle.CANTIDAD_PE,
          quantityReceived: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissing:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          quantityReceivedOriginal: singleArticle.CANTIDAD_RE
            ? singleArticle.CANTIDAD_RE
            : 0,
          quantityMissingOriginal:
            singleArticle.CANTIDAD_PR || singleArticle.CANTIDAD_PR === 0
              ? singleArticle.CANTIDAD_PR
              : singleArticle.CANTIDAD_PE,
          newReception: 0,
          RENGLON: singleArticle.RENGLON,
          iva: singleArticle.PORC_IVA,
          ieps: singleArticle.PORC_IEPS,
          price: singleArticle.COSTO_UNI,
          costTotal: singleArticle.COSTO_PAR,
          discountTotal: singleArticle.DESCTO_PAR,
          costs: singleArticle.COSTOS ? singleArticle.COSTOS : [],
          discounts: singleArticle.DESCS ? singleArticle.DESCS : [],
          total: singleArticle.TOTAL,
          selectedOption: "empty",
          EXENTO: singleArticle.EXENTO,
          IVA_GTO: singleArticle.IVA_GTO,
          IEPS_GTO: singleArticle.IEPS_GTO,
          PRECIO_ORIGINAL: singleArticle.PRECIO_ORIGINAL,
          PRESENTACION: singleArticle.PRESENTACION,
          CANTIDAD_PRESENTACION: singleArticle.CANTIDAD_PRESENTACION,
        });
      }
      let newPurchaseOrder = {
        _id: requestOrder._id,
        CLAVE_PED: requestOrder.CLAVE_PED,
        PRV: requestOrder.CLAVE_PRV,
        COSTOS: requestOrder.COSTOS ? requestOrder.COSTOS : [],
        DESCS: requestOrder.DESCS ? requestOrder.DESCS : [],
        ALMACEN: requestOrder.CLAVE_ALMACEN,
        OBSERVA_PED: requestOrder.OBSERVA_PED,
        STATUS_PED: requestOrder.STATUS_PED,
        currentItems: currentItems,
        creationDate: requestOrder.FECHA_PED,
        subtotal: requestOrder.SUBTOTAL_PED,
        total: requestOrder.TOTAL_PED,
        discounts: requestOrder.DESCS_GTO ? requestOrder.DESCS_GTO : 0,
        costs: requestOrder.COSTOS_GTO ? requestOrder.COSTOS_GTO : 0,
        iva: requestOrder.IVA_PED,
        ieps: requestOrder.IEPS,
        EXENTO: requestOrder.EXENTO,
        shippingQuantity: requestOrder.FLETE_PED,
        shippingPercentage: requestOrder.PORC_FLETEP,
        printTimes: requestOrder.IMPRESO,
        COMPRADOR: requestOrder.COMPRADOR,
        RESTA: requestOrder.RESTA,
        EMAIL_GUA: requestOrder.EMAIL_GUA,
        EMAIL_ACT: requestOrder.email,
        NUM_INTERNO: requestOrder.NUM_INTERNO,
        NUM_INTERNO_PROV: requestOrder.NUM_INTERNO_PROV,
        FECHA_PRE: requestOrder.FECHA_PRE,
        VENTA: requestOrder.VENTA,
        TIPO_CREACION: requestOrder.creationType,
      };
      return newPurchaseOrder;
    },
    showRequestOrder(requestOrder) {
      this.reloadInformation();
      let newPurchaseOrder = this.formatPurchaseOrder(requestOrder);
      let startingDateFormated = requestOrder.FECHA_PED;
      let creationDate = requestOrder.FECHA_PED;
      let currentIndex =
        Number(
          newPurchaseOrder.currentItems[
            newPurchaseOrder.currentItems.length - 1
          ].RENGLON
        ) + 1;
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ChangePurchaseOrder,
        props: {
          newPurchaseOrderProp: newPurchaseOrder,
          creationDateProp: new Date(creationDate),
          startingDateFormatedProp: startingDateFormated,
          currentIndexProp: currentIndex,
          type: "0",
          typeName: `Pedido #${requestOrder.CLAVE_PED}`,
          showReception: this.seePrices ? this.seePrices : this.showReception,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startGenerateReport(requestOrder) {
      this.reloadInformation();
      let newPurchaseOrder = this.formatPurchaseOrder(requestOrder);
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: PrintPurchaseOrder,
        props: {
          canConfirm: true,
          title: "Orden de compra",
          purchaseOrderInformation: newPurchaseOrder,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmDeleteRequestOrders() {
      if (this.checkedOrders.length > 0) {
        this.reloadInformation();
        this.$store
          .dispatch("DELETEREQUESTORDERS", {
            deleteRequestOrders: this.checkedOrders,
          })
          .then((response) => {
            if (response === "Success") {
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se borrarón exitosamente los pedidos seleccionados",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al borrar los pedidos en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay pedidos seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PED).getTime() || -Infinity) -
          (new Date(a.FECHA_PED).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PED).getTime() || -Infinity) -
          (new Date(b.FECHA_PED).getTime() || -Infinity)
        );
      }
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETREQUESTORDERS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
  },
  computed: {
    purchaseOrders() {
      return this.$store.getters.PURCHASEORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        purchaseOrderWithProps.NOMBRE_ALM =
          singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
        purchaseOrderWithProps.NOMBRE_PRV =
          singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
        purchaseOrderWithProps.formatedCreationDate = moment(
          singlePurchaseOrder.FECHA_PED
        ).format("DD-MM-YYYY HH:mm");
        return purchaseOrderWithProps;
      });
    },
    requestOrders() {
      return this.$store.getters.REQUESTORDERS.map((singlePurchaseOrder) => {
        let purchaseOrderWithProps = { ...singlePurchaseOrder };
        purchaseOrderWithProps.NOMBRE_ALM =
          singlePurchaseOrder.CLAVE_ALMACEN.NOMBRE_ALM;
        purchaseOrderWithProps.NOMBRE_PRV =
          singlePurchaseOrder.CLAVE_PRV.NOMBRE_PRV;
        purchaseOrderWithProps.formatedCreationDate = moment(
          singlePurchaseOrder.FECHA_PED
        ).format("DD-MM-YYYY HH:mm");
        return purchaseOrderWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLESPAGINATED.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        for (const singleUserWarehouse of this.userInformation.ALMACENES) {
          if (singleWarehouse._id === singleUserWarehouse) {
            return true;
          }
        }
        return false;
      });
    },
    filteredPurchaseOrder() {
      if (this.filterProvider === "") {
        return this.requestOrders.map((singleRequestOrder) => {
          let singleRequestOrderWithProps = { ...singleRequestOrder };
          return singleRequestOrderWithProps;
        });
      }
      return this.requestOrders.filter((singleRequestOrder) => {
        if (singleRequestOrder.CLAVE_PRV._id === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    userInformation() {
      return this.$store.getters.USER;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
