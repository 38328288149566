<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Cantidad de días para predicción
    </h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de días (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': predictionDaysErrors.DIAS }"
        :message="{
          'La cantidad de días no es válida': predictionDaysErrors.DIAS,
        }"
      >
        <b-input
          type="number"
          step="any"
          placeholder="Ejemplo: 30"
          expanded
          icon="numeric-1-circle"
          v-model="predictionDays.DIAS"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="startSmartPurchaseOrder()"
        >Generar predicción</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  name: "SelectPredictionDaysModal",
  props: [],
  components: {},
  data() {
    return {
      predictionDays: {
        DIAS: 30,
      },
      predictionDaysErrors: {
        DIAS: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async startSmartPurchaseOrder() {
      if (this.predictionDays.DIAS > 45) {
        this.predictionDaysErrors.DIAS = true;
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `La cantidad máxima de días para la predicción es 45`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
        return;
      }
      EventBus.$emit("startSmartPurchaseOrder", this.predictionDays);
      this.closeModal();
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
