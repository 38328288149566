<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Recepción de material </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Ordenes de compra recepción incompleta" icon="receipt">
        <PurchaseOrdersList
          type="pending"
          :showReception="true"
        ></PurchaseOrdersList>
      </b-tab-item>
      <b-tab-item
        label="Ordenes de compra recepción completa"
        icon="checkbox-marked-circle"
      >
        <PurchaseOrdersList
          type="received"
          :showReception="true"
        ></PurchaseOrdersList>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import moment from "moment";
import ReqestOrdersList from "../components/ReqestOrdersList";
import PurchaseOrdersList from "../components/PurchaseOrdersList";
// @ is an alias to /src
export default {
  name: "MaterialReceptionComponents",
  components: {
    PurchaseOrdersList,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "reception");
  },
  async created() {
    moment.locale("es");
    let allPromises = [];

    allPromises.push(
      this.$store.dispatch("GETPURCHASEORDERS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETREQUESTORDERS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(this.$store.dispatch("GETBANKS"));
    if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    }
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
