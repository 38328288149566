<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar costo de flete</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad del costo (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': CostRequiredErrors.CANTIDAD }"
        :message="{
          'La Cantidad del costo de flete no es valida':
            CostRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="4"
          placeholder="Ejemplo: 500"
          expanded
          icon="cash"
          v-model="newCost.CANTIDAD"
          required
          step="any"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="sendCost()"
        >Agregar costo de flete</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../../utils/fns";
import { EventBus } from "../../../../event-bus";
// @ is an alias to /src
export default {
  name: "shippingQuantity",
  props: ["shippingQuantity"],
  components: {},
  data() {
    return {
      newCost: {
        CANTIDAD: this.shippingQuantity,
      },
      CostRequiredErrors: {
        CANTIDAD: false,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async sendCost() {
      try {
        this.CostRequiredErrors = validateFields(
          this.newCost,
          this.CostRequiredErrors
        );
        if (checkValidationErrors(this.CostRequiredErrors)) {
          EventBus.$emit("addShippingCost", this.newCost);
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message: "Se agregó exitosamente el costo de flete",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar el costo de flete revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar el costo de flete",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
