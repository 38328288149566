<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar indirectos</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" @click="addCost()"
        >Agregar indirectos</b-button
      >
    </div>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado. </span> Los indirectos sin
      nombre no serán <span class="global-form-hint-color"> guardados. </span>
    </div>

    <div
      v-for="(singleCost, index) in costs"
      :key="index"
      class="margin-bottom-add"
    >
      <h3 class="form-division">Costo {{ singleCost.NOMBRE }}</h3>

      <b-field grouped class="special-margin">
        <b-field
          label="Nombre del costo (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descuento 1"
            expanded
            icon="file-outline"
            v-model="singleCost.NOMBRE"
            required
          ></b-input>
        </b-field>
        <b-field
          label="Descripción del costo (Opcional)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: Descripción 1"
            expanded
            icon="newspaper-variant-outline"
            v-model="singleCost.DESCRIPCION"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin">
        <b-field label="Tipo(Requerido)" expanded label-position="inside">
          <b-select
            placeholder="Tipo"
            icon="slash-forward"
            v-model="singleCost.TIPO"
            expanded
            class="required-input"
          >
            <option value="" disabled>Seleccione un tipo</option>
            <option value="PO">Porcentaje</option>
            <option value="CA">Cantidad</option>
          </b-select>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin" v-if="singleCost.TIPO === 'CA'">
        <b-field
          label="Cantidad del costo (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 500"
            expanded
            icon="currency-usd"
            v-model="singleCost.CANTIDAD"
            required
            type="number"
            step="any"
          ></b-input>
        </b-field>
      </b-field>

      <b-field grouped class="special-margin" v-if="singleCost.TIPO === 'PO'">
        <b-field
          label="Porcentaje del costo (Requerido)"
          expanded
          label-position="inside"
        >
          <b-input
            placeholder="Ejemplo: 500"
            expanded
            icon="percent-outline"
            v-model="singleCost.PORCENTAJE"
            required
            type="number"
            step="any"
          />
        </b-field>
      </b-field>

      <b-button type="is-danger" @click="removeCost(index)"
        >Eliminar costo {{ singleCost.NOMBRE }}</b-button
      >
    </div>

    <div class="add-global-controls providers-list-global">
      <b-button type="is-success" @click="sendGlobalCost()"
        >Guardar cambios</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
// @ is an alias to /src
export default {
  name: "AddGlobalCost",
  props: ["allCosts"],
  components: {},
  data() {
    return {
      costs: this.allCosts,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async sendGlobalCost() {
      try {
        if (!this.checkNotValid()) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error, revise los campos obligatorios de los indirecto",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }

        EventBus.$emit("saveGlobalCost", { costs: this.costs });

        this.$buefy.dialog.confirm({
          title: "Éxito",
          message: "Se agregaron exitosamente los indirectos",
          confirmText: "Entendido",
          type: "is-success",
          hasIcon: true,
          onConfirm: () => {
            this.closeModal();
          },
          cancelText: "cancelar",
          canCancel: false,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al agregar los indirectos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    checkNotValid() {
      for (const singleCost of this.costs) {
        if (!singleCost.NOMBRE) return false;
        if (singleCost.TIPO === "PO" && !singleCost.PORCENTAJE) return false;
        if (singleCost.TIPO === "CA" && !singleCost.CANTIDAD) return false;
      }
      return true;
    },
    removeCost(index) {
      this.costs.splice(index, 1);
    },
    addCost() {
      this.costs.push({
        NOMBRE: "",
        DESCRIPCION: "",
        TIPO: "PO",
        PORCENTAJE: 0,
        CANTIDAD: 1,
      });
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
</style>
