/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Recepción de material</h1>

    <div class="control add-global-controls">
      <b-button type="is-primary" @click="selectAllMissingArticles()"
        >Seleccionar todos los artículos faltantes</b-button
      >

      <b-button type="is-primary" @click="openBarcodeReader()"
        >Leer código de barras</b-button
      >
    </div>

    <div class="invoice-add-item">
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Proveedor"
      >
        <b-select
          placeholder="Proveedor"
          icon="earth"
          v-model="newPurchaseOrder.PRV.NOMBRE_PRV"
          disabled
          expanded
        >
          <option value="" disabled>Seleccione un proveedor</option>
          <option
            v-for="provider in providers"
            :value="provider.NOMBRE_PRV"
            :key="provider.NOMBRE_PRV"
          >
            {{ provider.NOMBRE_PRV }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Folio de orden de compra"
      >
        <b-input
          required
          placeholder="Número de orden"
          type="number"
          step="any"
          icon="pound"
          disabled
          v-model="newPurchaseOrder.CLAVE_PED"
        >
        </b-input>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four"
        label-position="inside"
        label="Fecha de orden de compra"
      >
        <b-datepicker
          required
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          v-model="creationDate"
          disabled
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-four required-input"
        label-position="inside"
        label="Almacenes"
      >
        <b-select
          expanded
          placeholder="Almacen"
          icon="warehouse"
          v-model="newPurchaseOrder.ALMACEN.CLAVE_ALMACEN"
          disabled
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse.CLAVE_ALMACEN"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="invoice-div-bottom-div s">
      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin">
        <b-switch
          v-model="newReceiveMaterial.includeShipping"
          true-value="S"
          false-value="N"
        >
          Incluir flete en cuenta por pagar
        </b-switch>
      </b-field>

      <div class="invoice-add-item">
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Número de serie (Opc)"
        >
          <b-input
            placeholder="Número de serie"
            icon="numeric-8-circle-outline"
            type="text"
            v-model="newReceiveMaterial.SERIE"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Número de factura (Req)"
          :type="{ 'is-danger': receiveOrderRequiredErrors.FACTURA }"
        >
          <b-input
            placeholder="Número de factura"
            icon="numeric-9-circle-outline"
            required
            type="text"
            v-model="newReceiveMaterial.FACTURA"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Fecha factura (Req)"
          :type="{ 'is-danger': receiveOrderRequiredErrors.FECHA_FAC }"
        >
          <b-datepicker
            :show-week-number="true"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            placeholder="Fecha factura"
            icon="calendar-today"
            v-model="newReceiveMaterial.FECHA_FAC"
            required
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Observaciones (Opc)"
        >
          <b-input
            placeholder="Número de orden"
            type="text"
            icon="magnify-plus-outline"
            v-model="newReceiveMaterial.OBSERVA"
          >
          </b-input>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin required-input"
          label-position="inside"
          label="Tipo de periodo (Req)"
          :type="{ 'is-danger': receiveOrderRequiredErrors.TIPO_PERI }"
        >
          <b-select
            placeholder="Tipo de periodo"
            icon="calendar-clock"
            v-model="newReceiveMaterial.TIPO_PERI"
            class="global-lists-filters-control"
            expanded
          >
            <option value="" disabled>Seleccione un tipo de periodo</option>
            <option value="FI">Fecha de ingreso</option>
            <option value="FF">Fecha de factura</option>
          </b-select>
        </b-field>
        <b-field
          class="invoice-add-secondary-item-three special-margin"
          label-position="inside"
          label="Plazo de pago en días (Req)"
          :type="{ 'is-danger': receiveOrderRequiredErrors.PERI }"
        >
          <b-input
            placeholder="Número de orden"
            icon="numeric-4-circle-outline"
            required
            type="type"
            v-model="newReceiveMaterial.PERI"
          >
          </b-input>
        </b-field>
      </div>

      <div class="global-lists-filters-columns">
        <div>
          <b-checkbox
            v-for="singleColumn in columns"
            v-bind:key="singleColumn.label"
            v-model="singleColumn.display"
          >
            {{ singleColumn.label }}
          </b-checkbox>
        </div>
      </div>

      <b-table
        pagination-position="both"
        :data="newPurchaseOrder.currentItems"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="purchaseOrder"
        custom-row-key="RENGLON"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        class="purchase-order-table"
        scrollable
        default-sort="article.DESCRIPCION"
      >
        <template slot-scope="props">
          <b-table-column
            label="Renglon"
            field="props.index"
            sortable
            :visible="columns[0].display"
          >
            <span> {{ props.index + 1 }}</span>
          </b-table-column>

          <b-table-column
            label="Artículo"
            field="article.DESCRIPCION"
            sortable
            searchable
            :visible="columns[1].display"
          >
            <span>{{ props.row.article.DESCRIPCION }}</span>
          </b-table-column>

          <b-table-column
            label="Clave del artículo"
            field="article.CLAVE_ART"
            sortable
            searchable
            :visible="columns[2].display"
          >
            <span v-if="props.row.article">{{
              props.row.article.CLAVE_ART
            }}</span>
          </b-table-column>

          <b-table-column label="Cantidad total" field="quantity" sortable>
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantity"
              disabled
              :visible="columns[3].display"
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad faltante total"
            field="quantityMissing"
            sortable
            :visible="columns[4].display"
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantityMissing"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad recibida total"
            field="quantityReceived"
            sortable
            :visible="columns[5].display"
          >
            <b-input
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.quantityReceived"
              disabled
            ></b-input>
          </b-table-column>

          <b-table-column
            label="Cantidad de nueva recepción"
            field="newReception"
            sortable
            :visible="columns[6].display"
          >
            <b-input
              type="number"
              step="any"
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.newReception"
              @input="
                (value) => {
                  registerNewReception(value, props.row);
                }
              "
            ></b-input>
          </b-table-column>

          <b-table-column
            label="IVA"
            field="iva"
            sortable
            :visible="columns[7].display"
          >
            <PercentageInput
              type="number"
              step="any"
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.iva"
            ></PercentageInput>
          </b-table-column>

          <b-table-column
            label="IEPS"
            field="ieps"
            sortable
            :visible="columns[8].display"
          >
            <PercentageInput
              type="number"
              step="any"
              placeholder="Ejemplo: 0"
              expanded
              v-model="props.row.ieps"
            ></PercentageInput>
          </b-table-column>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="receiveOrderArticles('C')"
        :disabled="buttonDisabled"
        >Recibir artículos</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import {
  checkValidationErrors,
  isNumber,
  round,
  validateFields,
} from "../../../utils/fns";
import BarcodeReaderModal from "@/modules/Sales/components/BarcodeReaderModal.vue";
import { EventBus } from "@/event-bus";
export default {
  name: "ReceiveOrderArticles",
  props: [
    "newPurchaseOrderProp",
    "creationDateProp",
    "startingDateFormatedProp",
    "currentIndexProp",
    "reveiveFullOrder",
    "xmlReceivedInformation",
  ],
  components: {},
  data() {
    return {
      // Table values
      newReceiveMaterial: {
        NUM_ORDEN: 0,
        CLAVE_TRAN: 0,
        FECHA_TRAN: new Date(),
        PROV: null,
        CVEALM1: "",
        STATUS_TRA: "",
        SERIE: "",
        FACTURA: "",
        FECHA_FAC: new Date(),
        OBSERVA: "",
        TIPO_PERI: "FI",
        PERI: 0,
        includeShipping: "N",
      },
      receiveOrderRequiredErrors: {
        FACTURA: false,
        FECHA_FAC: false,
        TIPO_PERI: false,
        PERI: false,
      },
      newPurchaseOrder: this.newPurchaseOrderProp,
      creationDate: this.creationDateProp,
      startingDateFormated: this.startingDateFormatedProp,
      currentIndex: this.currentIndexProp,
      firstWarehouseDisabled: false,
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          label: "Renglon",
          display: true,
        },
        {
          label: "Artículo",
          display: true,
        },
        {
          label: "Clave del artículo",
          display: false,
        },
        {
          label: "Cantidad total",
          display: true,
        },
        {
          label: "Cantidad faltante total",
          display: true,
        },
        {
          label: "Cantidad recibida total",
          display: true,
        },
        {
          label: "Cantidad de nueva recepción",
          display: true,
        },
        {
          label: "IVA",
          display: false,
        },
        {
          label: "IEPS",
          display: false,
        },
      ],
      buttonDisabled: false,
    };
  },
  async created() {
    moment.locale("es");
    this.startingDateFormated = moment(new Date()).format("DD-MM-YYYY HH:mm");
  },
  mounted() {
    this.newReceiveMaterial.ID_ORDE = this.newPurchaseOrder._id;
    this.newReceiveMaterial.NUM_ORDEN = this.newPurchaseOrder.CLAVE_PED;
    this.newReceiveMaterial.CLAVE_TRAN = this.lastWarehouseTransferNumber;
    this.newReceiveMaterial.CVEALM1 = this.newPurchaseOrder.ALMACEN;
    this.newReceiveMaterial.PROV = this.newPurchaseOrder.PRV;

    for (const singleProvider of this.providers) {
      if (this.newReceiveMaterial.PROV._id === singleProvider._id) {
        this.newReceiveMaterial.PERI = singleProvider.DIAS_CRD;
      }
    }

    if (this.reveiveFullOrder) {
      this.selectAllMissingArticles();
    }

    this.getAllDoneTransfers();

    if (this.xmlReceivedInformation) {
      for (const singleXMLArticles of this.xmlReceivedInformation) {
        for (const [
          singleArticleIndex,
          singleArticle,
        ] of this.newPurchaseOrder.currentItems.entries()) {
          if (
            singleArticle.article._id.toString() ===
            singleXMLArticles.article.toString()
          ) {
            this.newPurchaseOrder.currentItems[
              singleArticleIndex
            ].newReception =
              singleXMLArticles.quantity * singleArticle.CANTIDAD_PRESENTACION;
          }
        }
      }
    }

    EventBus.$on("barcodeRead", async (barCode) => {
      for (const [
        singleItemIndex,
        singleItem,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (singleItem.article.CODIGO_BARRAS === barCode.code) {
          this.newPurchaseOrder.currentItems[singleItemIndex].newReception
            ? (this.newPurchaseOrder.currentItems[
                singleItemIndex
              ].newReception += 1)
            : (this.newPurchaseOrder.currentItems[
                singleItemIndex
              ].newReception = 1);

          this.registerNewReception(
            this.newPurchaseOrder.currentItems[singleItemIndex].newReception,
            this.newPurchaseOrder.currentItems[singleItemIndex]
          );
        }
      }
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    getAllDoneTransfers() {
      let total = 0;
      let subtotal = 0;
      let subtotalNoExtras = 0;
      let iva = 0;
      let ieps = 0;
      let cost = 0;
      let costsTotal = 0;
      let discount = 0;
      let discountsTotal = 0;

      let transfersToDo = [];

      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        if (
          isNumber(singleArticle.newReception) &&
          singleArticle.newReception > 0
        ) {
          let costItem = 0;
          let discountItem = 0;
          let totalIvaItem = 0;
          let totalIepsItem = 0;
          let subTotalItem = 0;

          let priceQuantity = singleArticle.price * singleArticle.newReception;

          for (const cost of singleArticle.costs) {
            costItem += cost.CANTIDAD * singleArticle.CANTIDAD_PRESENTACION;
          }

          costsTotal += singleArticle.newReception * costItem;

          for (const discount of singleArticle.discounts) {
            discountItem +=
              discount.CANTIDAD * singleArticle.CANTIDAD_PRESENTACION;
          }

          discountsTotal += singleArticle.newReception * discountItem;

          subTotalItem =
            priceQuantity +
            singleArticle.newReception * costItem -
            singleArticle.newReception * discountItem;

          subtotal += subTotalItem;
          subtotalNoExtras += priceQuantity;

          let totalItem = 0;
          totalItem += subTotalItem;

          if (singleArticle.iva > 0) {
            let itemIva = subTotalItem * (singleArticle.iva / 100);
            totalItem += itemIva;
            iva += itemIva;
            totalIvaItem += itemIva;
          }

          if (singleArticle.ieps > 0) {
            let itemIeps = subTotalItem * (singleArticle.ieps / 100);
            totalItem += itemIeps;
            ieps += itemIeps;
            totalIepsItem += itemIeps;
          }

          transfersToDo.push({
            ...singleArticle,
            article: singleArticle.article._id,
            name: singleArticle.article.DESCRIPCION,
            key: singleArticle.article.CLAVE_ART,
            IMP_COST: costItem,
            IMP_DESC: discountItem,
            TOTAL: totalItem,
            SUB_TOT: subTotalItem,
            IVA_GTO: totalIvaItem,
            IEPS_GTO: totalIepsItem,
            DESC_GTO: discountItem * singleArticle.newReception,
            COST_GTO: costItem * singleArticle.newReception,
          });
        }
      }

      let currentSubtotal = subtotal;
      for (let singleDiscount of this.newPurchaseOrder.DESCS) {
        if (singleDiscount.TIPO === "PO") {
          let discountQuantity =
            currentSubtotal * (singleDiscount.PORCENTAJE / 100);
          currentSubtotal -= discountQuantity;
          discount += discountQuantity;
          discountsTotal += discountQuantity;
          iva -= discountQuantity * 0.16;
        } else if (singleDiscount.TIPO === "CA") {
          discount += Number(singleDiscount.CANTIDAD);
          discountsTotal += Number(singleDiscount.CANTIDAD);
          iva -= Number(singleDiscount.CANTIDAD) * 0.16;
        }
      }

      for (let singleCost of this.newPurchaseOrder.COSTOS) {
        if (singleCost.TIPO === "PO") {
          let costQuantity = currentSubtotal * (singleCost.PORCENTAJE / 100);
          currentSubtotal += costQuantity;
          cost += costQuantity;
          costsTotal += costQuantity;
          iva += costQuantity * 0.16;
        } else if (singleCost.TIPO === "CA") {
          cost += Number(singleCost.CANTIDAD);
          costsTotal += Number(singleCost.CANTIDAD);
          iva += Number(singleCost.CANTIDAD) * 0.16;
        }
      }

      total = subtotal - discount + cost + iva + ieps;

      if (this.newReceiveMaterial.includeShipping === "S") {
        total += this.newPurchaseOrder.shippingQuantity;
        this.newReceiveMaterial.FLETE = this.newPurchaseOrder.shippingQuantity;
      }

      this.newReceiveMaterial.IVA = round(Number(iva).toFixed(5), 2);
      this.newReceiveMaterial.IEPS = round(Number(ieps).toFixed(5), 2);
      this.newReceiveMaterial.DESC = this.newPurchaseOrder.discounts;
      this.newReceiveMaterial.COSTOS = this.newPurchaseOrder.costs;
      this.newReceiveMaterial.DESC_GTO = round(
        Number(discountsTotal).toFixed(5),
        2
      );
      this.newReceiveMaterial.COST_GTO = round(
        Number(costsTotal).toFixed(5),
        2
      );
      this.newReceiveMaterial.SUB_TOTAL = round(
        Number(subtotalNoExtras).toFixed(5),
        2
      );
      this.newReceiveMaterial.TOTAL = round(Number(total).toFixed(5), 2);
      return transfersToDo;
    },
    checkValidQuantities() {
      for (const singleArticle of this.newPurchaseOrder.currentItems) {
        if (!singleArticle.article.POR_EXCEDENTE) {
          return (
            singleArticle.quantityMissingOriginal -
              Number(singleArticle.newReception) <
            0
          );
        }

        if (
          singleArticle.article.POR_EXCEDENTE &&
          singleArticle.quantityMissing < 0
        ) {
          let allowedExtra = Math.ceil(
            singleArticle.quantity * (singleArticle.article.POR_EXCEDENTE / 100)
          );

          return (
            singleArticle.quantityMissingOriginal +
              allowedExtra -
              Number(singleArticle.newReception) <
            0
          );
        }
      }

      return false;
    },
    async receiveOrderArticles(confirm) {
      try {
        this.buttonDisabled = true;
        this.articleRequiredErrors = validateFields(
          this.newReceiveMaterial,
          this.receiveOrderRequiredErrors
        );
        if (checkValidationErrors(this.receiveOrderRequiredErrors)) {
          if (this.checkValidQuantities()) {
            this.buttonDisabled = false;
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar la recepción de artículos, la recepción es mayor a la cantidad solicitad",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }
          let itemsToTranfer = this.getAllDoneTransfers();
          this.newReceiveMaterial.items = itemsToTranfer;
          this.newReceiveMaterial.STATUS_TRA = confirm;
          if (itemsToTranfer.length > 0) {
            let response = await this.$store.dispatch(
              "RECEIVEMATERIAL",
              this.newReceiveMaterial
            );
            if (response === "Success") {
              this.buttonDisabled = false;
              this.$buefy.dialog.confirm({
                title: "Éxito",
                message: "Se guardó exitosamente la recepción de material",
                confirmText: "Entendido",
                type: "is-success",
                hasIcon: true,
                onConfirm: () => {
                  this.closeModal();
                },
                cancelText: "cancelar",
                canCancel: false,
              });
            } else {
              this.buttonDisabled = false;
              this.$buefy.dialog.confirm({
                title: "Error",
                message:
                  "Hubo un error al guardar la recepción de material en la base de datos",
                confirmText: "Entendido",
                type: "is-danger",
                hasIcon: true,
                cancelText: "cancelar",
                canCancel: false,
              });
            }
          } else {
            this.buttonDisabled = false;
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error no hay artículos que recibir",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.buttonDisabled = false;
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al guardar la recepción de material en la base de datos revise los campos Requeridos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.buttonDisabled = false;
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la recepción de material en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    registerNewReception(value, article) {
      article.quantityMissing =
        Number(article.quantityMissingOriginal) - Number(value);
      article.quantityReceived =
        Number(article.quantityReceivedOriginal) + Number(value);
    },
    selectAllMissingArticles() {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.newPurchaseOrder.currentItems.entries()) {
        this.newPurchaseOrder.currentItems[singleArticleIndex].newReception =
          singleArticle.quantityMissingOriginal;
        this.registerNewReception(
          singleArticle.quantityMissingOriginal,
          singleArticle
        );
      }
    },
    /**
     *
     * @desc open the barcode reader
     */
    openBarcodeReader() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: BarcodeReaderModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    lastWarehouseTransferNumber() {
      return this.$store.getters.LASTWAREHOUSETRANSFERNUMBER;
    },
    providers() {
      return this.$store.getters.PROVIDERS;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    creationDate() {
      this.startingDateFormated = moment(
        this.newPurchaseOrder.creationDate
      ).format("DD-MM-YYYY HH:mm");
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    lastWarehouseTransferNumber() {
      this.newReceiveMaterial.CLAVE_TRAN = this.lastWarehouseTransferNumber;
    },
    provider() {
      for (const singleProvider of this.providers) {
        if (this.newReceiveMaterial.PROV._id === singleProvider._id) {
          this.newReceiveMaterial.PERI = singleProvider.DIAS_CRD;
        }
      }
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
