<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Buscar artículos</h1>

    <div class="table-header table-header-border">
      Cantidad de Artículos: <b>{{ articles.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :data="articles"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      ref="selectArticleTable"
      paginated
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      class="purchase-order-table row-pointer"
      scrollable
      @click="(row) => selectPrice(row)"
    >
      <template #empty>
        <div class="has-text-centered">No hay productos</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          label="Clave del artículo"
          field="CLAVE_ART"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.CLAVE_ART }}</span>
        </b-table-column>

        <b-table-column
          label="Descripción del artículo"
          field="DESCRIPCION"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          <span>{{ props.row.DESCRIPCION }}</span>
        </b-table-column>

        <b-table-column
          label="Costo máximo de compra"
          field="price"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ props.row.COSTO_MIN_VTA | money("MXN", 5) }}
        </b-table-column>

        <b-table-column
          label="Inventario total"
          field="totalQuantity"
          searchable
          sortable
          :cell-class="props.row.DESCONTINUADO ? 'red-cell' : ''"
        >
          {{ props.row.totalQuantity }}
        </b-table-column>
      </template>
    </b-table>

    <div class="add-global-controls">
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
// @ is an alias to /src
export default {
  name: "SelectArticle",
  props: ["currentArticles", "providerInformation", "renglon"],
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 50,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async selectPrice(article) {
      EventBus.$emit("searchArticleSelected", {
        article: article,
        renglon: this.renglon,
      });
      this.closeModal();
    },
    articlenotFound(article) {
      for (const singleArticle of this.currentArticles) {
        if (
          singleArticle.article &&
          article._id.toString() === singleArticle.article._id.toString()
        ) {
          return true;
        }
      }

      return false;
    },
  },
  computed: {
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        articleWithProps.totalQuantity = 0;

        // Get available items
        if (singleArticle.CANT_ALMACENES) {
          for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
            articleWithProps.totalQuantity += Number(
              singleArticleWarehouse.CANTIDAD
            );
          }
        }

        return articleWithProps;
      }).filter((singleArticle) => {
        for (const singleProviderPrice of singleArticle.PRECIOS_PROV) {
          if (
            singleProviderPrice.CLAVE_PRV.toString() ===
              this.providerInformation._id.toString() &&
            (singleArticle.ACTIVO_COMPRA ||
              singleArticle.ACTIVO_COMPRA === undefined ||
              singleArticle.ACTIVO_COMPRA === null)
          ) {
            return !this.articlenotFound(singleArticle);
          }
        }

        return false;
      });
    },
  },
};
</script>

<style scoped>
@import "../../../Global/style/Global.css";
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
