<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Usar secreto</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Código a buscar (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': warehouseUnlockRequiredErrors.CODIGO }"
        :message="{
          'La descripción del secreto no es válida':
            warehouseUnlockRequiredErrors.CODIGO,
        }"
      >
        <b-input
          placeholder="Ejemplo: Almacen"
          expanded
          icon="numeric"
          v-model="warehouseUnlock.CODIGO"
          required
        ></b-input>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="searchWarehouseUnlock()"
        >Buscar desbloqueo de inventario</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { EventBus } from "../../../event-bus";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "SearchWarehouseUnlock",
  props: ["saleInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      warehouseUnlock: {
        CODIGO: "",
      },
      warehouseUnlockRequiredErrors: {
        CODIGO: false,
      },
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async searchWarehouseUnlock() {
      try {
        this.warehouseUnlockRequiredErrors = validateFields(
          this.warehouseUnlock,
          this.warehouseUnlockRequiredErrors
        );
        if (checkValidationErrors(this.warehouseUnlockRequiredErrors)) {
          let response = await this.$store.dispatch("SEARCHUNLOCKWAREHOUSE", {
            code: this.warehouseUnlock.CODIGO,
            warehouseId: this.saleInformation.ALMACEN,
          });

          if (response.warehouseUnlock) {
            EventBus.$emit("warehouseUnlockFound", {
              warehouseUnlock: response.warehouseUnlock,
            });

            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se desbloqueo exitosamente el almacen",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "No se encontró el código buscado",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al buscar el código en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al buscar el código en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
